<template>
  <el-table-column :label="label" type="index" width="50">
    <span slot-scope="scope">
      <cell-inner :key="getKey()" :data="scope"/>
    </span>
  </el-table-column>
</template>

<script>
import cellInner from './cellInner';

export default {
  components: { cellInner },
  props: {
    label: {
      type: String,
      default: '序号'
    }
  },
  computed: {},
  methods: {
    getKey() {
      return Date.now() + '-' + (Math.random() * 999999);
    }
  }
}
</script>

<style lang="scss" scoped></style>
