<template>
    <div class="amr-progress">
        <img class="thumb" :src="thumb" />
        <div class="amr-media"> 
            <div :class="{
                'play-btn': true,
                'playing': playing
            }" @click="controlRecord">
                <div class="la-line-scale-party">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="aplayer" ref="amrdom"></div>
    </div>
</template>
<script>
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';
export default {
    name: 'amrMusicPlay',
    props: {
        width: {
            type: String,
            default: '50px'
        },
        type: {
            type: String,
            default: 'bgm'
        },
        music: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            amr: null,
            value1: 0,
            playing: false,
            state: null,
        }
    },
    computed:{
      thumb() {
        if(this.type === 'bgm') {
            return require('@/assets/images/ai/icon-music-bgm.jpg');
        }else{
            return require('@/assets/images/ai/icon-voice.png');
        }
      }
    },
    mounted(){
        if(this.music){
            this.amr = new APlayer({
                container: this.$refs.amrdom,
                mini: false,
                autoplay: false,
                loop: 'none',
                preload: 'metadata',
                volume: 0.2,
                mutex: true,
                listFolded: true,
                audio: [{
                    name: '',
                    artist: '',
                    url: this.music
                }]
            });
            this.amr.on('canplay',()=>{
                
            });
            this.amr.on('pause',()=>{
                this.playing = false;
                this.state = '停止';
                this.playToPause();
            });
            this.amr.on('ended',()=>{
                this.value1 = 0;
                this.playing = false;
                this.state = '播放结束';
                this.playToStop();
            });
        }
    },
    beforeDestroy(){
        if(this.amr && this.playing){
            this.amr.destroy();
            this.value1 = 0;
            this.playing = false;
            this.state = '停止';
            this.playToStop();
        }
    },
    methods: {
        playToStop(){
        },
        playToPause(){
        },
        controlRecord(){
            if(this.amr.audio.paused){
                this.amr.play();
                this.playing = true;
                this.state = '播放中';
                return;                
            }else{
                this.amr.pause();
                this.playing = false;
                this.state = '停止';
                this.playToPause();
                return;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.aplayer{
    width: 0;
    height: 0;
}
.thumb {
    position: absolute;
    width: 100%;
    height: calc(100% + 40px);
    top: -20px;
    bottom: -20px;
    object-fit: contain;
}
.amr-progress{
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    .icon-cell-o{
        display: inline-flex;
        width: 42px;
        height: 42px;
        margin-right: 7px;
        flex: none;
    }
    .icon-bgm-cell,.icon-voice-cell{
        display: inline-block;
        width: 42px;
        height: 42px;
        background: url("@/assets/images/ai/icon-bgm.png") center center no-repeat;
        vertical-align: middle;
        &.icon-voice-cell{
            background: url("@/assets/images/ai/icon-voice.png") center center no-repeat;
            vertical-align: middle;
        }
    }
    .amr-media{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        z-index: 1;
        justify-content: center;
        align-items: center;
        margin: 50px;
        .top,.slider{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
        .top{
            font-size: 12px;
            span{
                font-size: 12px;
                display: inline-block;
                margin: 2px 30px 0 0;
            }
            .name-tag{
                font-size: 12px;
                margin-left: 5px;
                opacity: 0.6;
            }
        }
        .slider{
            height: 20px;
        }
        :deep{
            .el-slider__runway{
                margin: 10px 6px;
                cursor: default;
            }
            .el-slider__button-wrapper{
                width: 24px;
                height: 24px;
                top: -10px;
                cursor: default;
            }
            .el-slider__button{
                width: 12px;
                height: 12px;
                border-color: #ffffff;
                box-shadow: 0 1px 5px rgba(0,0,0,0.4);
                cursor: default;
            }
        }
    }
}
.play-btn{
    display: inline-block;
    width: 60px;
    height: 60px;
    background: url("@/assets/images/ai/video_play_btn.png") center center no-repeat;
    background-size: 100% 100%;
    vertical-align: middle;
    cursor: pointer;
    line-height: initial;
    &.playing{
        background: none;
        .la-line-scale-party{
            display: block;
        }
    }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-line-scale-party,
.la-line-scale-party > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.la-line-scale-party {
    display: block;
    font-size: 0;
    color: #ffffff;
}
.la-line-scale-party.la-dark {
    color: #333;
}
.la-line-scale-party > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
// .la-line-scale-party {
//     width: 40px;
//     height: 32px;
// }
.la-line-scale-party > div {
    width: 4px;
    height: 32px;
    margin: 2px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    -webkit-animation-name: line-scale-party;
       -moz-animation-name: line-scale-party;
         -o-animation-name: line-scale-party;
            animation-name: line-scale-party;
    -webkit-animation-iteration-count: infinite;
       -moz-animation-iteration-count: infinite;
         -o-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}
.la-line-scale-party > div:nth-child(1) {
    -webkit-animation-duration: .43s;
       -moz-animation-duration: .43s;
         -o-animation-duration: .43s;
            animation-duration: .43s;
    -webkit-animation-delay: -.23s;
       -moz-animation-delay: -.23s;
         -o-animation-delay: -.23s;
            animation-delay: -.23s;
}
.la-line-scale-party > div:nth-child(2) {
    -webkit-animation-duration: .62s;
       -moz-animation-duration: .62s;
         -o-animation-duration: .62s;
            animation-duration: .62s;
    -webkit-animation-delay: -.32s;
       -moz-animation-delay: -.32s;
         -o-animation-delay: -.32s;
            animation-delay: -.32s;
}
.la-line-scale-party > div:nth-child(3) {
    -webkit-animation-duration: .43s;
       -moz-animation-duration: .43s;
         -o-animation-duration: .43s;
            animation-duration: .43s;
    -webkit-animation-delay: -.44s;
       -moz-animation-delay: -.44s;
         -o-animation-delay: -.44s;
            animation-delay: -.44s;
}
.la-line-scale-party > div:nth-child(4) {
    -webkit-animation-duration: .8s;
       -moz-animation-duration: .8s;
         -o-animation-duration: .8s;
            animation-duration: .8s;
    -webkit-animation-delay: -.31s;
       -moz-animation-delay: -.31s;
         -o-animation-delay: -.31s;
            animation-delay: -.31s;
}
.la-line-scale-party > div:nth-child(5) {
    -webkit-animation-duration: .74s;
       -moz-animation-duration: .74s;
         -o-animation-duration: .74s;
            animation-duration: .74s;
    -webkit-animation-delay: -.24s;
       -moz-animation-delay: -.24s;
         -o-animation-delay: -.24s;
            animation-delay: -.24s;
}
.la-line-scale-party.la-sm {
    width: 20px;
    height: 16px;
}
.la-line-scale-party.la-sm > div {
    width: 2px;
    height: 16px;
    margin: 1px;
    margin-top: 0;
    margin-bottom: 0;
}
.la-line-scale-party.la-2x {
    width: 80px;
    height: 64px;
}
.la-line-scale-party.la-2x > div {
    width: 8px;
    height: 64px;
    margin: 4px;
    margin-top: 0;
    margin-bottom: 0;
}
.la-line-scale-party.la-3x {
    width: 120px;
    height: 96px;
}
.la-line-scale-party.la-3x > div {
    width: 12px;
    height: 96px;
    margin: 6px;
    margin-top: 0;
    margin-bottom: 0;
}
/*
 * Animation
 */
@-webkit-keyframes line-scale-party {
    0% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }
    50% {
        -webkit-transform: scaleY(.3);
                transform: scaleY(.3);
    }
    100% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }
}
@-moz-keyframes line-scale-party {
    0% {
        -moz-transform: scaleY(1);
             transform: scaleY(1);
    }
    50% {
        -moz-transform: scaleY(.3);
             transform: scaleY(.3);
    }
    100% {
        -moz-transform: scaleY(1);
             transform: scaleY(1);
    }
}
@-o-keyframes line-scale-party {
    0% {
        -o-transform: scaleY(1);
           transform: scaleY(1);
    }
    50% {
        -o-transform: scaleY(.3);
           transform: scaleY(.3);
    }
    100% {
        -o-transform: scaleY(1);
           transform: scaleY(1);
    }
}
@keyframes line-scale-party {
    0% {
        -webkit-transform: scaleY(1);
           -moz-transform: scaleY(1);
             -o-transform: scaleY(1);
                transform: scaleY(1);
    }
    50% {
        -webkit-transform: scaleY(.3);
           -moz-transform: scaleY(.3);
             -o-transform: scaleY(.3);
                transform: scaleY(.3);
    }
    100% {
        -webkit-transform: scaleY(1);
           -moz-transform: scaleY(1);
             -o-transform: scaleY(1);
                transform: scaleY(1);
    }
}

.la-line-scale-party{
    width: auto;
    height: 50px;
    display: none;
 }
</style>