import passwordInput from './passwordInput';
import svgIcon from './svgIcon';
import imgBgBox from './imgBgBox';
import contentLayout from './contentLayout';
import numberFastChange from './numberFastChange';
import pvDialog from './pvDialog';
import pvDateRangePicker from './pvDateRangePicker';
//
import pvTabs from './pvTabs';
import pvTabPane from './pvTabs/pvTabPane';
//
import pvPagination from './pvPagination';
//
import pvList from './pvList';
import pvIndexColumn from './pvIndexColumn';
import pvListSearchForm from './pvList/searchForm';
//
import pvUploader from './pvUploader';
import pvVideo from './pvVideo';
import pvAudio from './pvAudio';
import VueDragResize from './pvDrag/vue-drag-resize.vue'

import aiDataLoading from './aiDataLoading';

export default {
  install(Vue) {
    Vue.component('password-input', passwordInput);
    Vue.component('svg-icon', svgIcon);
    Vue.component('img-bg-box', imgBgBox);
    Vue.component('content-layout', contentLayout);
    Vue.component('number-fast-change', numberFastChange);
    Vue.component('pv-dialog', pvDialog);
    Vue.component('pv-date-range-picker', pvDateRangePicker);
    //
    Vue.component('pv-tabs', pvTabs);
    Vue.component('pv-tab-pane', pvTabPane);
    //
    Vue.component('pv-pagination', pvPagination);
    //
    Vue.component('pv-list', pvList);
    Vue.component('pv-index-column', pvIndexColumn);
    Vue.component('pv-list-search-form', pvListSearchForm);
    //
    Vue.component('pv-uploader', pvUploader);
    Vue.component('pv-video', pvVideo);
    Vue.component('pv-audio', pvAudio);

    Vue.component('vue-drag-resize', VueDragResize);

    Vue.component('ai-data-loading', aiDataLoading);
  }
}
