export default {
  //文章分类
  cate: {
    GET_LIST: '/api/help/list', //文章分类列表
    ADD: '/api/help/add', //新增文章分类
    EDIT: '/api/help/add', //修改文章分类
    DELETE: '/api/help/del', //删除文章分类
    GET_ALL_LIST: '/api/help/listAll', //获取所有分类
  },
  //文章列表
  article: {
    GET_LIST: '/api/help/articleList', //文章列表
    ADD: '/api/help/addArticle', //新增文章
    EDIT: '/api/help/addArticle', //修改文章
    DELETE: '/api/help/delArticle', //删除文章
    //
    DETAIL: '/api/help/articleDetails', //根据文章id文章详情
  },
  web: {
    //获取所有文章和分类  web端-用户访问(不用登录)
    GET_TOTAL_DATA: '/api/helpWeb/list', //废弃-2024-10-21
    //web端-一级，二级分类（10.21优化）
    GET_TOTAL_TYPES: '/api/helpWeb/help',
    //二级分类下-所有文章信息(10.21优化)
    GET_ARTICLES_BY_TYPEID: '/api/helpWeb/article',
    GET_ARTICLEINFO_BY_ID: '/api/helpWeb/info', 
    //搜索
    SEARCH: '/api/helpWeb/getSearch',
    SHARE_URL: '/api/helpWeb/jssdk',
  }
}
