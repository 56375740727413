<template>
  <div :style="style" class="img-bg-box" :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    bgUrl: String
  },
  data() {
    return {
      wMax: false,
      checking: false,
      imgSize: {
        width: null,
        height: null
      }
    };
  },
  computed: {
    style() {
      const style = {};
      style.backgroundImage = `url("${this.bgUrl}")`;
      return style;
    },
    className() {
      console.log('wMax', this.wMax);
      if (this.wMax) {
        return 'w-max';
      }
      return '';
    }
  },
  methods: {
    handleResize() {
      if (!this.checking) {
        this.checking = true;
        const winWidth = window.innerWidth;
        const winHeight = window.innerHeight;
        const sizeSync = () => {
          if (winWidth / winHeight > this.imgSize.width / this.imgSize.height) {
            this.wMax = true;
          } else {
            this.wMax = false;
          }
          this.checking = false;
        };
        if (!this.imgSize.width || !this.imgSize.height) {
          const img = new Image();
          img.onload = () => {
            this.imgSize.width = img.width;
            this.imgSize.height = img.height;
            sizeSync();
          };
          img.src = this.bgUrl;
        } else {
          sizeSync();
        }
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.img-bg-box {
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;

  &.w-max {
    background-size: 100% 1387px;
    background-repeat: repeat;
  }
}
</style>
