import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        //初始页面
        path: '/',
        name: 'root',
        component: () => import(/* webpackChunkName: "root" */ '../views/root/index.vue')
    },
    {
        //登录
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
    },
    {
        //忘记密码/重置密码
        path: '/reset/password',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "resetPassword" */ '../views/resetPassword/index.vue')
    },
    {
        //登录后显示的界面
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/index/index.vue'),
        children: require('./home')
    },
    // {
    //     //ai视频【帮聚专用】
    //     path:'/bondtech',
    //     name: 'bondtech',
    //     component: () => import(/* webpackChunkName: "home" */ '../views/home/aibondtech/index.vue'),
    //     children: require('./bondtech')
    // },
    {
        //专题预览
        path: '/preview',
        name: 'preview',
        component: () => import(/* webpackChunkName: "home/subject" */ '../views/home/subject/preview.vue'),
        props: (route) => ({ id: route.query.id }),
    },
    {
        path: '/guide',
        name: 'guide',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/guide.vue'),
        props: (route) => ({ gtype: route.query.gtype }),
    },
    {
      // AI账号助手客户端下载
      path:'/download/ai/account/assistant',
      name:'downloadAiAccountAssistant',
      component: () => import(/* webpackChunkName: "download/ai/account/assistant" */ '../views/download/ai/account/assistant/index.vue'),
    },
    {
      // 帮助中心文章
      path:'/help/articles',
      name:'helpArticles',
      component: () => import(/* webpackChunkName: "help/articles" */ '../views/help/articles/index.vue'),
    },
]

const router = new VueRouter({
    routes
})

export default router
