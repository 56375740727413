import api from '@/api';
import request from '@/lib/request';
import { setToken, clearToken } from "./token";
import _ from 'lodash';
import { Message } from 'element-ui';
import { getGlobalData } from '@/lib/globalData';
import store from '@/store';

//执行退出登录
export const userLogout = () => {
  const globalData = getGlobalData();
  clearToken();
  globalData.clear();
  clearLocalStorage();
  sessionStorage.clear();
  store.dispatch('setUserAuth', null);
  store.dispatch('setTopNewmsg', 0);
  store.dispatch('setTopUser', {
    weixin: 0,
    douyin: 0,
  });
}; 

//清空localStorage 时不删除saveLoginInfo
// 用于回填账号密码
const clearLocalStorage = () => {
  const saveLoginInfo = localStorage.getItem('saveLoginInfo');
  localStorage.clear();
  if (saveLoginInfo) {
    localStorage.setItem('saveLoginInfo', saveLoginInfo);
  }
};

//执行用户登录
export const userLogin = async (username, password) => {
    let success = true;
    const params = {};
    params.uname = username.trim();
    params.password = password;
    const { err, res } = await request.post(api.user.LOGIN, params);
    const token = _.get(res, 'data.token'); //如果登录成功就能获取到token
    if (err) { //登录失败
        Message.error(err);
        success = false;
        userLogout();
    } else if (token) { //登录成功
        setToken(token);
    }
    return success;
};

//登录【帮聚专用】
export const bondLogin = async () => {
    let success = true;
    const { err, res } = await request.get(api.user.BOND_LOGIN);
    const token = _.get(res, 'data.token'); //如果登录成功就能获取到token
    if (err) { //登录失败
        Message.error(err);
        success = false;
        userLogout();
    } else if (token) { //登录成功
        setToken(token);
    }
    return success;
};

//获取用户信息
export const getUserInfo = async () => {
    const { res } = await request.get(api.user.GET_USER_INFO);
    const userInfo = _.get(res, 'data');
    return userInfo;
};


//新增用户
export const addUser = async (data) => {
    const { err } = await request.post(api.user.ADD, data);
    const success = !err;
    err && Message.error(err);
    return success;
};

// 编辑用户
export const updateUser = async (data) => {
    const { err } = await request.post(api.user.UPDATE, data);
    const success = !err;
    err && Message.error(err);
    return success;
};

//修改自己的密码
export const updateSelfPassword = async (password) => {
    const params = {};
    params.password = password;
    params.repass = password;
    const { err } = await request.post(api.user.UPDATE_SELF_PASSWORD, params);
    const success = !err;
    err && Message.error(err);
    return success;
};


//重置密码
export const userResetPassword = async (uid) => {
    const { err } = await request.post(api.user.RESET_PASSWORD, { uid });
    const success = !err;
    err && Message.error(err);
    return success;
};

//用户绑定企业微信
export const userBindWxWork = async ({ uid, userid }) => { //uid用户id  userid企业微信id
    const params = {};
    params.uid = uid;
    params.userid = userid;
    const { err } = await request.post(api.user.BIND_WX_WORK, params);
    const success = !err;
    err && Message.error(err);
    return success;
};

//用户解绑企业微信
export const userUnBindWxWork = async (uid) => { //uid用户id  userid企业微信id
    const params = {};
    params.uid = uid;
    const { err } = await request.post(api.user.UNBIND_WX_WORK, params);
    const success = !err;
    err && Message.error(err);
    return success;
};

//获取抖音授权信息
export const getUserDouYinAuthInfo = async () => {
    const { res } = await request.post(api.user.GET_DOUYIN_AUTH_INFO);
    return _.get(res, 'data');
};

//切换门店
export const switchChannel = async (id) => {
    const params = {};
    params.id = id;
    const { err, res } = await request.post(api.user.SWITCH_CHANNEL, params);
    const success = !err;
    err && Message.error(err);
    if (success) {
        const token = _.get(res, 'data.token');
        token && setToken(token);
    }
    return success;
};

//绑定智能客服
export const userBindAisbot = async (uid) => { //uid用户id
    const params = {};
    params.uid = uid;
    const { err } = await request.post(api.user.BIND_AISBOT, params);
    const success = !err;
    err && Message.error(err);
    return success;
};

//解绑智能客服
export const userUnBindAisbot = async (uid) => { //uid用户id
    const params = {};
    params.uid = uid;
    const { err } = await request.post(api.user.UNBIND_AISBOT, params);
    const success = !err;
    err && Message.error(err);
    return success;
};
