<template>
  <div class="pv-video">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      @play="handlePlay"
      @pause="handlePause"
      @playing="handlePlaying"
      @error="handleError"
      :playsinline="true"
      :options="playerOptions"></video-player>
    <div class="cover-box" v-if="!playing">
      <i class="play-icon" @click="play"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    type: { type: String, default: 'video/mp4' },
    coverSrc: String,
    options: Object,
    autoPlay: Boolean
  },
  data() {
    return {
      playing: false
    };
  },
  computed: {
    playerOptions() { // 配置信息
      const opt = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'none', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:10', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: this.src, // 路径
          type: this.type // 类型
        }],
        poster: this.coverSrc, // 你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      };
      if (this.options) {
        Object.assign(opt, this.options);
      }
      return opt;
    }
  },
  mounted() {
    if (this.autoPlay) {
      this.play();
    }
  },
  methods: {
    play() {
      this.playing = true;
      this.$refs.videoPlayer.player.play();
    },
    handlePlay() {
      this.playing = true;
    },
    handlePause() {
      this.playing = false;
    },
    handlePlaying() {
      this.playing = true;
    },
    handleError() {
      this.playing = false;
      this.$emit('error');
    }
  }
}
</script>

<style lang="scss" scoped>
.pv-video {
  position: relative;
  background-color: #000000;

  .cover-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .play-icon {
    position: absolute;
    display: block;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    border-radius: 50%;
    cursor: pointer;
    background-image: url("@/assets/images/home/video-play-icon.png");
    background-size: 100% 100%;
  }

  :deep {
    .vjs-big-play-button {
      display: none;
    }
  }
}

.video-player {
  width: 100%;
  height: 100%;

  .video-js {
    width: 100%;
    height: 100%;
  }
}
</style>
