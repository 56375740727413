export default {
  //经销商热度
  dealer: {
    //抖音视频列表-经销商热度
    GET_LIST: '/api/admin/douyin/getDealerHotVideoList',
    //抖音视频列表详情-经销商热度
    DETAIL: '/api/admin/douyin/getDealerVideoList',
    //抖音账号列表-经销商热度(点击总数显示)
    ACCOUNT_LIST: '/api/admin/douyin/getUsersVideoList',
    //账户热度列表|账户分析
    HOT_USER_LIST: '/api/admin/douyin/getHotUserList',
    HOT_USER_LIST_EXPORT: '/api/export/douyin/getHotUserList', 
  },
  //视频热度-视频分析
  video: {
    //视频热度列表|视频分析
    GET_LIST: '/api/admin/douyin/getHotVideoList',
    //导出
    GET_LIST_EXPORT: '/api/export/douyin/getHotVideoList',
    //视频收藏
    SET_FOCUS: '/api/admin/douyin/videoFocus',
    //视频存至内容中心
    SAVE_VIDEO_AS: '/api/admin/douyin/move'
  },
  //分析中心
  report: {
    //总览数据
    BENCH_TOP: '/api/aivideos/bench/top',
    //作品概览
    BENCH_WORKS: '/api/aivideos/bench/works',
    //数据分析-指标趋势分析
    BENCH_TRENDS: '/api/aivideos/bench/trends',
    //数据分析-账号总览
    BENCH_USERS: '/api/aivideos/bench/user',
    //视频榜
    RANK_VIDEO_LIST: '/api/aivideos/bench/video/rank',
    //账号榜
    RANK_USER_LIST: '/api/aivideos/bench/user/rank',
    //单个视频分析详情
    CHART_TRENDS: '/api/admin/douyin/getHotVideoInfo',

    //主题对比-获取主题类型列表
    VIDEO_THEME_TYPES: '/api/aivideos/getVideoTheme',
    //主题对比-搜索指数图表数据
    VIDEO_THEMETYPE_STATIS: '/api/aivideos/getVideoStatistics',
    //主题对比-获取主题类型排行榜
    VIDEO_THEMETYPE_RANK: '/api/aivideos/getVideoRank',

  }
}
