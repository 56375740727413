<template>
  <div class="ailoading-center">
    <div class="ailoading-center-absolute">
      <span class="ailoading-center-font">AI</span>
      <div class="objline objline_four"></div>
      <div class="objline objline_three"></div>
      <div class="objline objline_two"></div>
      <div class="objline objline_one"></div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  }
}
</script>
  
<style lang="scss" scoped>
.ailoading-center {
  width: 400px;
  height: 160px;
  // height: 106px;
  position: relative;
}
.ailoading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -120px;
  margin-left: -100px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.ailoading-center-font {
  position: absolute;
  transform: rotate(135deg);
  top: -8px;
  left: -8px;
  margin: 50%;
  font-size: 14px;
  color: #3d82fc;
  font-weight: bold;
}
.objline {
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 3px solid #3d82fc;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #3d82fc;
  border-right: 3px solid transparent;
  //   border-top: 5px solid #404d5b;
  //   border-bottom: 5px solid transparent;
  //   border-left: 5px solid #404d5b;
  //   border-right: 5px solid transparent;

  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}
.objline_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

.objline_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.objline_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.objline_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes animate {
  50% {
    -ms-transform: rotate(360deg) scale(0.8);
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}

@keyframes animate {
  50% {
    -ms-transform: rotate(360deg) scale(0.8);
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}
</style>
  