export default {
  //上传文件
  UPLOAD: '/api/admin/material/upload',
  //获取省市区列表
  GET_REGION_LIST: '/api/admin/city/list',
  //获取区域列表
  GET_ORG_LIST: '/api/admin/organ/list',
  UPDATE_SYSLOGO: '/api/admin/logo/upload',
  //获取客户端版本信息
  GET_DEVICE_VERSION_INFO: '/api/admin/device/version',
  //美图编辑
  MEITU_GET_IMAGE: '/api/aimeitu/meituGetImage',
}
