import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueVideoPlayer from 'vue-video-player';
import normalComponents from './normalComponents'
import globalData from './lib/globalData';

import './assets/css/element-variables.scss';
import './assets/css/global.scss';
import 'video.js/dist/video-js.css';
import './permission.js';

// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();
// console.log("vConsole=>", vConsole);

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueVideoPlayer);
Vue.use(normalComponents);
Vue.use(globalData);

import permission from './directive/permission/index.js';
Vue.use(permission);

//高德地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
    // 高德的key
    key: 'b631b0c4d139c55305af98f47fb41ae3',
    // 插件集合
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch','AMap.Geocoder', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'AMap.CitySearch'],
    // 高德 sdk 版本，默认为 1.4.4
    v: '1.4.4'
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
