export default {
    //用户登录
    LOGIN: '/api/admin/login',
    //获取已经登录的用户信息
    GET_USER_INFO: '/api/admin/profile',
    // 获取用户列表
    GET_LIST: '/api/admin/member/list',
    // 新增用户
    ADD: '/api/admin/member/create',
    // 编辑用户
    UPDATE: '/api/admin/member/update',
    //修改自己的密码
    UPDATE_SELF_PASSWORD: '/api/admin/member/password/update',
    //重置密码
    RESET_PASSWORD: '/api/admin/member/password/reset',
    //用户绑定企业微信
    BIND_WX_WORK: '/api/admin/member/userid/bind',
    //用户解绑企业微信
    UNBIND_WX_WORK: '/api/admin/member/userid/unbind',
    //获取抖音授权信息
    GET_DOUYIN_AUTH_INFO: '/api/admin/douyin/getDouYinInfo',
    //切换门店
    SWITCH_CHANNEL: '/api/admin/channel/switch',
    //绑定智能客服
    BIND_AISBOT: '/api/admin/member/aisbot/bind',
    //解绑智能客服
    UNBIND_AISBOT: '/api/admin/member/aisbot/unbind',
    //【帮聚登录专用】
    BOND_LOGIN: '/index/91jch/login',
}
