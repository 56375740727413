import jsCookie from 'js-cookie'

export default {
  get(key) {
    return jsCookie.get(key);
  },
  set(key, value) {
    return jsCookie.set(key, value);
  },
  remove(key) {
    jsCookie.remove(key);
    if(document.domain){
        jsCookie.remove(key, { domain: document.domain });
        jsCookie.remove(key, { domain: '.'+document.domain });
    }
  }
}
