export default {
  //获取新增删除标签
  tag: {
    //获取标签列表
    GET_LIST: '/api/admin/material/classify/list',
    // 内容|素材分类-分配素材-列表
    SELECT_LIST: '/api/admin/material/classify/select',
    //新增标签
    ADD: '/api/admin/material/classify/create',
    //编辑标签
    UPDATE: '/api/admin/material/classify/update',
    //删除标签
    DELETE: '/api/admin/material/classify/delete'
  },
  //下发
  send: {
    //执行发放操作
    DISPATCH_SEND: '/api/admin/material/publish',
    //获取下发列表
    GET_LIST: '/api/admin/material/publish/list',
    //编辑下发信息
    UPDATE: '/api/admin/material/publish/update',
    //删除下发信息
    DELETE: '/api/admin/material/publish/delete'
  },
  //获取内容列表
  GET_LIST: '/api/admin/material/list', //module  功能模块 1:内容 2:素材 3:专题素材
  //上传素材(添加内容)
  ADD: '/api/admin/material/create',
  //编辑素材
  UPDATE: '/api/admin/material/update',
  //删除素材
  DELETE: '/api/admin/material/delete',
  //获取详情
  DETAIL: '/api/admin/material/info',
  //下载统计
  DOWNLOAD_STATISTICS: '/api/admin/material/down',
  //审核通过
  VERIFY_PASS: '/api/admin/material/pass',
  //审核驳回
  VERIFY_UN_PASS: '/api/admin/material/reject',
  //仅修改名称
  UPDATE_NAME: '/api/admin/material/name/update',
  //批量设置标签
  SET_CLASSIFY: '/api/admin/material/multiset/classify',
  //收藏
  SET_FOCUS: '/api/admin/material/favor',
  //取消收藏
  SET_UN_FOCUS: '/api/admin/material/unfavor',
  //获取下载详情
  GET_DOWNLOAD_DETAIL_LIST: '/api/admin/report/material/down/list',
  //获取拥有素材的经销商列表
  GET_DEALER_MATERIAL_LIST: '/api/admin/dealer/material/list',
}
